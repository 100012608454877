/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { Button } from '@/components/Button';
import { Grid } from '@/components/Grid';
import cx from 'classnames';
import NotificationBlock from '@/components/AZCustomComponent/NotificationBlock';
import FormikTextField from '@/components/AZCustomComponent/FormGroup/TextField/formikTextField';
import { useLabels } from '@/hooks/useLabels';
import azCommonStyles from '@/theme/globals.module.scss';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import styles from './VINDecoderForm.module.scss';
import type { FormikValues, VINDecoderProps } from './types';
import Hidden from '@/components/Hidden';
import { setInteractionLocation } from '../utils/ymmeLocationHelper';
import { useVINLookup } from '../api/getVehicleByVIN';
import { VIN } from '@/utils/validatorRegex';

export const VINDecoderForm = ({
  buttonText,
  onVinLookupSuccess,
  children,
  classes,
}: VINDecoderProps) => {
  const [vinNumber, setVinNumber] = useState<string>();
  const [hasError, setHasError] = useState(false);

  const {
    isSuccess,
    isLoading,
    isError,
    fetchStatus,
    data: vinLookupData,
  } = useVINLookup({
    vinNumber,
  });
  const { scanVinButton, formLabel } = children || {};
  const labels = useLabels();

  const initialValues: FormikValues = {
    vinNumber: '',
  };

  if (vinLookupData?.length) {
    setInteractionLocation('Enter VIN');
    onVinLookupSuccess?.(vinLookupData);
  }

  const handleSubmit = ({ vinNumber }: FormikValues) => {
    onVinLookupSuccess?.([]);
    const trimmedVinNumber = vinNumber.trim();

    if (!trimmedVinNumber) {
      return;
    }
    setVinNumber(vinNumber);

    if ((!vinLookupData?.length && isSuccess) || isError) {
      setHasError(true);
    }
  };

  const validate = (values: { vinNumber: string }) => {
    setHasError(false);

    const errors: Record<string, string | undefined> = {};
    const { vinNumber = '' } = values || {};
    const trimmedValue = vinNumber.trim();

    if (!trimmedValue) {
      errors.vinNumber = labels.label_please_enter_a_vin_number;
    } else if (trimmedValue && !VIN.test(trimmedValue)) {
      errors.vinNumber = labels.label_VinDecoder_Validation_Error;
    }

    return errors;
  };

  useEffect(() => {
    if ((!vinLookupData?.length && isSuccess) || isError) {
      setHasError(true);
    }
  }, [vinLookupData, isSuccess, isError]);

  return (
    <div
      className={cx(
        styles.vinDecoderContainer,
        classes?.vinModalContentContainer
          ? {
              [`${classes.vinModalContentContainer}`]: classes.vinModalContentContainer,
            }
          : ''
      )}
    >
      {!!scanVinButton && <Hidden mdUp>{scanVinButton}</Hidden>}
      {formLabel}
      <Grid container spacing={0}>
        <Grid item xs={12}>
          {hasError && (
            <div className={azCommonStyles['az-margin-top-xxs']}>
              <NotificationBlock type="error" message={labels.label_vin_decoder_no_matches} />
            </div>
          )}
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validate={validate}
            validateOnChange={true}
            validateOnBlur={false}
          >
            {({ errors, values, setErrors }) => (
              <Form action="#" autoComplete="off" noValidate>
                <Grid
                  container
                  spacing={0}
                  className={azCommonStyles['az-padding-top-4xs']}
                  alignItems="flex-start"
                >
                  {!!scanVinButton && (
                    <Hidden smDown lgUp className={styles.vinScanButtonContainer}>
                      {scanVinButton}
                    </Hidden>
                  )}
                  <Grid className={styles.vinNumberInput} item xs>
                    <Field
                      name="vinNumber"
                      id="vinLookup"
                      autoComplete="off"
                      required
                      component={FormikTextField}
                      label={labels.label_VinDecoder_Search_Placeholder}
                      className={styles.searchBox}
                      value={values.vinNumber}
                      onBlur={() => !values.vinNumber && setErrors({})}
                      showErrorIfNotTouched
                    />
                  </Grid>
                  <Grid item xs={12} md={3} className={styles.vinLookupButton}>
                    <Button
                      type="submit"
                      variant="contained"
                      fullWidth
                      loading={isLoading && fetchStatus !== 'idle'}
                      customClass={styles.searchButton}
                      data-testid="ymme-vin-lookup-button"
                      disabled={!!errors.vinNumber || !values.vinNumber}
                    >
                      {buttonText || labels.label_VinDecoder_Search_Button}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </div>
  );
};
