/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export const vehicleIcon = {
  src: '/images/vehicle-new.svg',
  alt: 'vehicle icon',
};
